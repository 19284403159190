.scrollableArea::-webkit-scrollbar {
  display: none;
}

.scrollableArea {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollableWrapper::after {
  content: "";
  pointer-events: none;
  opacity: 0;
  position: absolute;
  background: linear-gradient(
    270deg,
    #f4f4f6 18.75%,
    rgba(244, 244, 246, 0) 100%
  );
  top: 1px;
  right: 0;
  bottom: 0;
  width: 70px;
}

.isVisibleOverflow::after {
  opacity: 1;
}
