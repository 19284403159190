.marquee {
  will-change: transform;
  animation: marquee 30s linear infinite;
}

.marquee-copy {
  will-change: transform;
  animation: marquee-copy 30s linear infinite;
}

.marquee-container:hover > ul {
  animation-play-state: paused;
}

@keyframes marquee {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes marquee-copy {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}
