.burgerSlice {
  @apply bg-current;
  position: absolute;
  height: 2px;
  opacity: 1;
  left: 1px;
  right: 0;
  width: 22px;
}

.topSlice {
  composes: burgerSlice;
  top: 3px;
}

.middleSlice {
  composes: burgerSlice;
  top: 11px;
}

.bottomSlice {
  composes: burgerSlice;
  top: 19px;
}
