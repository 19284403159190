.checkbox {
  position: relative;
  cursor: pointer;
}
.checkbox::selection,
.checkbox::-moz-selection {
  background: transparent;
}

.checkbox input + span {
  margin-top: 3px;
  content: "";
  display: inline-block;
  padding: 0;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  border: 2px solid #767683;
  background: white;
}

.checkbox input + span::after {
  content: "";
  display: block;
  transform: scale(0);
  transition: transform 0.2s;
  background: url('data:image/svg+xml,<svg viewBox="-2.5 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 4.11111L5 8L11 1" stroke="white" stroke-width="2"/></svg>')
    center / contain no-repeat;
  width: 20px;
  height: 20px;
}

.checkbox input:checked + span::after {
  transform: scale(1) translate(-2px, -2px);
}

.checkbox input {
  position: absolute;
  cursor: pointer;
  opacity: 0;
}

.checkbox input:checked + span {
  border: 2px solid #da145e;
  background: #da145e;
}
